import React, { Fragment } from "react";

import Seo from "../../components/seo";

import BladeStudy from "../../containers/bladeStudy";

const BladeStudyPage = () => {
  return (
    <Fragment>
      <Seo title="Contact" />
      <BladeStudy />
    </Fragment>
  );
};

export default BladeStudyPage;
