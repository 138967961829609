import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 600px;
`;

const Link = styled.a`
  font-size: 8vw;
  text-decoration-color: black;
  ${"" /* text-decoration: none; */}
  transition: color 0.3s ease-in-out, text-decoration-color 0.3s ease-in-out;
  &:hover {
    ${"" /* border-bottom: 1px solid black; */}
    ${"" /* text-decoration */}
  }
`;

const BladeStudy = () => (
  <Link href="https://bladestudy.net" target="_blank">
    bladestudy.net
  </Link>
);

export default BladeStudy;
